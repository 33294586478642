/* Global
======================================== */
html,
body {
    height: auto;
}

body {
    font-family: 'Poppins', sans-serif;
}

.object-fit-contain {
    object-fit: contain;
}

.object-fit-cover {
    object-fit: cover;
}

/* Page wrapper
======================================== */
.page-wrapper {
    overflow-x: hidden;
}

/* Filters
======================================== */
.filter-options-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Fancybox
========================================= */
.fancybox-inner {
    .fancybox-content {
        max-width: 750px !important;

        @include media-breakpoint-up(xs) {
            padding: 1.5rem;
        }

        @include media-breakpoint-up(md) {
            padding: 2.5rem;
        }

        h3 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
    }
}

/* Buttons
========================================= */
.action {
    &.primary {
        background-color: $secondary;
        border-color: $secondary;
        border-radius: 0.25rem;

        &:hover,
        &:active,
        &:focus {
            background-color: darken($secondary, 10%);
            border-color: darken($secondary, 10%);
            color: #fff;
        }
    }
}

.ambrands-letters-list {
    align-items: center;

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-wrap: wrap;
    }

    .ambrands-brand-item {
        @include media-breakpoint-down(sm) {
            min-height: inherit !important;
        }

        .ambrands-inner {
            box-shadow: none !important;

            &:hover {
                border-color: $secondary !important;
            }
        }

        .ambrands-label {
            display: none;
        }
    }

    .ambrands-letter {
        margin-bottom: 0 !important;

        @include media-breakpoint-down(sm) {
            width: 50% !important;
        }

        .ambrands-content {
            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
        }

        .ambrands-title {
            display: none !important;
        }
    }
}
