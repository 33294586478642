.catalog-product-view {
    .page-header {
        margin-bottom: 1.5rem;
    }

    .breadcrumbs {
        padding: 0;
    }

    .page-main {
        .page-title-wrapper {
            margin-bottom: 1.5rem;
            position: relative;

            .page-title {                
                font-weight: 600;
                margin-bottom: 0;

                @include media-breakpoint-down(sm) {
                    margin-bottom: .5rem;
                }

                @include media-breakpoint-up(xs) {
                    font-size: 1.7rem;
                }

                @include media-breakpoint-up(md) {
                    font-size: 2.8rem;
                }                
            }

            .sku {
                display: flex;

                .type {
                    font-weight: 600;
                    margin-right: 4px;

                    &:after {
                        content: ": ";
                    }
                }
            }
        }

        .badge-label {
            font-weight: 600;
            pointer-events: none;

            @include media-breakpoint-down(sm) {
                position: absolute;
                left: 15px;
                top: 0;
                z-index: 2;
            }

            @include media-breakpoint-up(md) {
                font-size: 1.2rem;
            }
        }              

        .media {
            @include media-breakpoint-down(sm) {
                margin: 0 -15px;
            }

            .gallery-placeholder {
                width: 100%;

                @include media-breakpoint-down(sm) {
                    height: 200px;
                    position: relative;
                }

                .gallery-placeholder__image {
                    height: inherit;
                }

                .fotorama {
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        height: 200px;
                    }        
                    
                    .fotorama__wrap {
                        @include media-breakpoint-down(sm) {
                            height: 100%;
                        }

                        .fotorama__stage {
                            @include media-breakpoint-down(sm) {
                                height: calc(100% - 30px) !important;
                            }
                        }

                        .fotorama__nav--dots {
                            .fotorama__nav__frame {
                                .fotorama__dot {
                                    height: 8px;
                                    width: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .product-info-main {
            position: relative;

            .product-info-price {                
                @include media-breakpoint-up(xs) {
                    margin-top: 1rem;
                }

                @include media-breakpoint-up(md) {
                    margin-top: 1.5rem;
                }

                .price-box {
                    margin: 0;
                }

                .special-price__wrapper {
                    .special-price {
                        margin: 0;
                    }

                    .old-price {
                        margin-left: .75rem;
                        color: #999;

                        .price {
                            font-size: 1.7rem;
                            font-weight: 500;
                        }

                        .price-final_price {
                            margin: 0;
                        }
                    }

                    .price-label {
                        display: none;
                    }
                }

                .price-final_price {
                    .price {
                        margin-left: -4px;
                        font-size: 2rem;
                    }
                }
            }

            .prices-tier {
                background-color: transparent;
                margin-top: .5rem;
                margin-bottom: 0;
                padding: 0;

                .item {
                    font-size: 1.3rem;

                    .price {
                        margin-left: -4px;
                    }
                }
            }

            .product-add-form {
                background-color: $light;
                position: relative;      

                @include media-breakpoint-down(sm) {
                    margin: 1rem -15px 0;
                }

                @include media-breakpoint-up(xs) {
                    padding: 10px 15px;
                }

                @include media-breakpoint-up(md) {
                    padding: 15px;
                    margin-top: 2rem;
                }                

                form {
                    display: flex;
                    flex-direction: column;
                }

                .cross-sells {
                    order: 2;

                    @include media-breakpoint-down(sm) {
                        display: flex;
                        align-items: center;
                    }

                    strong {
                        font-weight: 600;

                        @include media-breakpoint-down(sm) {
                            font-size: 1.3rem;
                            white-space: nowrap;
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                    }

                    select {
                        display: inline-block;
                        width: 100%;
                        height: calc(1.5em + 2rem + 2px);
                        padding: .375rem 1.75rem .375rem .75rem;
                        font-weight: 500;
                        line-height: 1.5;
                        font-size: 1.3rem;
                        color: #495057;
                        vertical-align: middle;
                        background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
                        border: 1px solid #ced4da;
                        border-radius: .25rem;
                        appearance: none;
                        border-radius: 0;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                .box-tocart {                    
                    margin-bottom: 0;
                    width: 100%;
                    order: 3;

                    @include media-breakpoint-up(xs) {
                        margin-top: .5rem;
                    }

                    @include media-breakpoint-up(md) {
                        margin-top: 1rem;
                    }

                    .fieldset {
                        width: 100%;        
                        margin-bottom: 0;   
                        display: flex;    
                        align-items: flex-end;         

                        @include media-breakpoint-up(md) {
                            align-items: flex-start;
                            flex-direction: column;
                        }

                        @include media-breakpoint-up(lg) {
                            align-items: flex-end;
                            flex-direction: row;
                        }

                        .qty {
                            margin-bottom: 0;

                            @include media-breakpoint-down(sm) {
                                padding-right: 10px;
                            }

                            .label {
                                font-size: 1.3rem;
                                font-weight: 600;                            
                            }

                            .control {
                                display: flex;

                                button {
                                    background-color: #fff;

                                    @include media-breakpoint-up(xs) {
                                        width: 40px;
                                    }                                    

                                    @include media-breakpoint-up(md) {
                                        width: 50px;
                                    }
                                }

                                .input-text {
                                    margin: 0 .5rem;

                                    @include media-breakpoint-down(sm) {
                                        height: 42px;
                                        width: 42px;
                                    }

                                    &:focus {
                                        box-shadow: none;
                                    }
                                }
                            }
                        }

                        .actions {
                            width: 100%;
                            padding: 0;

                            @include media-breakpoint-between(md,lg) {
                                margin-top: 1rem;
                            }

                            .tocart {
                                background-color: $secondary;
                                border: 0;
                                border-radius: .5rem; 
                                box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.2);
                                width: 100%;
                                text-transform: lowercase;

                                @include media-breakpoint-down(sm) {
                                    padding: 1rem;
                                }

                                @include media-breakpoint-up(xs) {
                                    font-size: 1.5rem;
                                }

                                @include media-breakpoint-up(md) {
                                    font-size: 1.7rem;
                                }

                                &::first-letter {
                                    text-transform: capitalize;
                                }
    
                                &:hover {
                                    background-color: #CB521A;
                                }
                            }
                        }
                    }
                }

                .product-info-stock-sku {
                    order: 1;
                    text-align: right;

                    .stock {
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: .5rem;
                        }

                        &:after {
                            content: "";
                            width: 5px;
                            height: 5px;                            
                            border-radius: 100%;
                        }                        

                        span {
                            margin-right: 5px;                            

                            @include media-breakpoint-up(xs) {
                                font-size: 1.1rem;
                            }

                            @include media-breakpoint-up(md) {
                                font-size: 1.3rem;
                            }
                        }
                    }

                    .available {
                        color: #44bd3a;

                        &:after {
                            background: #44bd3a;
                        }
                    }

                    .unavailable {
                        color: #e30a0a;
                        position: absolute;
                        top: 0;
                        right: 0;

                        &:after {
                            background: #e30a0a;
                        }                        
                    }
                }
            }

            .product-meta {
                margin-top: 1.5rem;

                .usps {
                    margin-top: 1.5rem;
                    margin-bottom: 0;

                    li {
                        @include media-breakpoint-down(sm) {
                            font-size: 1.3rem;
                        }

                        i {
                            @include media-breakpoint-down(sm) {
                                width: 14px;
                                height: 10px;
                            }
                        }
                    }
                }
            }  
            
            //Out of stock
            .product {
                &.stock {
                    padding: 0;

                    .alert {
                        background-color: $secondary;
                        border: 0;
                        border-radius: .5rem;
                        font-size: 1.7rem;           
                        box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.2);
                        width: 100%;
                        color: #fff;
                        display: block;
                        text-align: center;
                        font-size: 1.5rem;
                        font-weight: 500;
                        padding: 1.5rem 1rem;
                        margin-bottom: 0;

                        &:hover {
                            background-color: #CB521A;
                        }                        
                    }               
                }
            }
        }
    }

    .amshopby-option-link {
        position: absolute;
        top: 100%;
        margin: 0;
        z-index: 1;

        @include media-breakpoint-down(sm) {
            right: 0;
        }

        @include media-breakpoint-up(md) {
            left: calc(57% - 100px);
        }

        img {
            margin: 0;

            @include media-breakpoint-down(sm) {
                width: 50px;
            }
        }
    }

    //Tabs
    .product { 
        &.info { 
            &.detailed {
                @include media-breakpoint-down(sm) {
                    margin: 1.5rem -15px 0;
                }

                @include media-breakpoint-up(md) {
                    padding-top: 3rem;
                }

                .items {
                    &:before {
                        @include media-breakpoint-up(md) {
                            content: '';
                            position: absolute;
                            display: block;
                            width: 300%;
                            height: 1px;
                            background-color: #e3e3e3;
                            top: 52px;
                            left: -100%;
                            bottom: 0;                        
                        }
                    }
                }

                .title {
                    margin: 0;

                    &.active {
                        .switch {
                            @include media-breakpoint-down(sm) {
                                background-color: $light;
                            }

                            @include media-breakpoint-up(md) {
                                padding-bottom: 1.5rem !important;
                                border-bottom: 3px solid $secondary;
                            }

                            &:hover {
                                @include media-breakpoint-up(md) {
                                    padding-bottom: 1.5rem;
                                }
                            }

                            &:after {
                                @include media-breakpoint-down(md) {
                                    transform: rotate(-90deg);
                                }
                            }
                        }
                    }

                    &:not(.active) {
                        .switch {
                            font-weight: 400;

                            @include media-breakpoint-down(sm) {
                                border-bottom: 1px solid #dedede;
                            }
                        }
                    }

                    .switch {
                        height: auto;                        
                        border: 0;                        

                        @include media-breakpoint-up(xs) {
                            background: $light;
                            font-size: 1.3rem;
                            padding: .5rem 15px;
                        }

                        @include media-breakpoint-up(md) {
                            background: transparent;
                            font-size: 1.6rem;
                            padding: 1.5rem 2.5rem;
                            border-bottom: 3px solid transparent;
                        }                        

                        &:after {
                            @include media-breakpoint-down(sm) {
                                content: "";
                                mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='276.5px' height='512px' viewBox='0 0 276.5 512' style='enable-background:new 0 0 276.5 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M270.7,242L33.9,5.8c-7.8-7.7-20.3-7.7-28.1,0c-7.7,7.8-7.7,20.3,0,28.1L228.6,256L5.8,478.1c-7.8,7.7-7.8,20.3,0,28.1 c3.9,3.9,9,5.8,14.1,5.8c5.1,0,10.1-1.9,14-5.8l236.8-236.2c3.7-3.7,5.8-8.8,5.8-14S274.4,245.7,270.7,242z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                                width: 8px;
                                height: 14px;
                                mask-size: contain;
                                mask-repeat: no-repeat;
                                background-color: #000;
                                display: inline-block;
                                float: right;
                                margin-top: 15px;     
                                margin-right: 10px;
                                transform: rotate(90deg);                           
                            }
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent !important;
                            border-color: $secondary;

                            @include media-breakpoint-up(md) {
                                padding-bottom: 1.5rem !important;
                            }
                        }
                    }
                }

                .content {                    
                    border: 0;

                    @include media-breakpoint-up(xs) {
                        margin-top: 1.5rem;
                        padding: 0 15px;
                    }

                    @include media-breakpoint-up(md) {
                        margin-top: 8rem;
                        padding: 0;
                    }

                    .description {
                        @include media-breakpoint-up(md) {
                            max-width: 50%;
                        }

                        h2 {                            
                            font-weight: 600;                            

                            @include media-breakpoint-up(xs) {
                                font-size: 1.6rem;
                                margin-bottom: 1rem;
                            }

                            @include media-breakpoint-up(md) {
                                font-size: 2.4rem;
                                margin-bottom: 1.5rem;
                            }
                        }

                        p {
                            @include media-breakpoint-up(xs) {
                                line-height: 1.6;
                                margin-bottom: 1.5rem;
                            }

                            @include media-breakpoint-up(md) {
                                line-height: 1.7;
                                margin-bottom: 2rem;
                            }
                        }
                    }

                    &#additional {
                        .additional-attributes-wrapper {
                            .additional-attributes {
                                @include media-breakpoint-up(md) {
                                    max-width: 50%;
                                }

                                tr {
                                    &:nth-child(even) {
                                        background: #f2f2f2;
                                    }

                                    th {
                                        font-weight: 500;
                                    }

                                    th,
                                    td {
                                        padding: 10px 15px;
                                    }
                                }
                            }
                        }
                    }

                    &#reviews {
                        .review-list {
                            .block-title {
                                strong {
                                    font-size: 2.4rem;
                                    font-weight: 600;
                                    margin-bottom: 1.5rem;
                                }
                            }

                            .review-items {
                                .review-item {
                                    padding: 2rem 0;

                                    .review-title {
                                        margin-bottom: 1rem;
                                        font-weight: 500;
                                        font-weight: 600;
                                        font-size: 1.6rem;                                        
                                    }

                                    .review-details {
                                        font-size: 1.3rem;
                                    }
                                }
                            }
                        }

                        .review-add {
                            padding: 2rem;
                            background-color: #f2f2f2;

                            .review-form {
                                @include media-breakpoint-up(md) {
                                    max-width: 50%;
                                }

                                .review-fieldset {
                                    margin-bottom: 1.5rem;

                                    .review-legend {
                                        margin-bottom: 1.5rem;

                                        span {
                                            font-size: 1.8rem;
                                        }
                                    }
                                }

                                .review-form-actions {
                                    .submit {
                                        background-color: $secondary;
                                        border: 0;
                                        color: #fff;
                                        border-radius: .5rem;

                                        &:hover {
                                            background-color: #CB521A;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }   
    
    .upsell {
        @include media-breakpoint-down(sm) {
            margin: 1rem -15px 0;
        }

        .block-title {
            @include media-breakpoint-down(sm) {
                margin: 0 15px;
            }

            strong {
                display: block;
            }
        }

        .product-items {
            padding: 0;

            @include media-breakpoint-down(sm) {
                margin: 0;
            }

            @include media-breakpoint-up(md) {
                margin: 0 -10px;
            }

            .product-item {
                @include media-breakpoint-up(lg) {
                    width: 25%;
                }
            }
        }
    }
}