.page-main {
    //Login
    .login-container {
        .block {            
            background-color: $light;
            margin-bottom: 0;

            @include media-breakpoint-up(xs) {
                padding: 1.5rem;
            }

            @include media-breakpoint-up(md) {
                padding: 2.5rem;
            }

            &.block-new-customer {
                @include media-breakpoint-down(sm) {
                    margin-top: 1.5rem;
                }
            }

            .block-title {
                padding: 0;
                border: 0;

                @include media-breakpoint-up(xs) {
                    margin-bottom: .5rem;
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 1rem;
                }

                strong {
                    font-weight: 600;
                }
            }
        }
    }

    form {
        &.form {
            &.password,
            &.create {
                background-color: $light;   
                width: 100%;          
                
                @include media-breakpoint-up(xs) {
                    padding: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    padding: 2.5rem;
                }           
            } 

            .choice {
                &:before {
                    display: none;
                }
            }
        }

        .password-strength-meter {
            font-size: 1.3rem;
            background-color: #e1e1e1;
        }
    }

    //Create 
    form {
        &.create {
            fieldset {
                &.info,
                &.account {
                    @include media-breakpoint-up(md) {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .field {
                        width: 100%;
                    }

                    .field-name-firstname,
                    .field-name-lastname,
                    .password,
                    .confirmation {
                        @include media-breakpoint-up(md) {
                            width: 50%;
                        }
                    }

                    .field-name-firstname,
                    .password {
                        @include media-breakpoint-up(md) {
                            padding-right: 1rem;
                        }
                    }

                    .field-name-lastname,
                    .confirmation {
                        @include media-breakpoint-up(md) {
                            padding-left: 1rem;
                        }
                    }                

                    .choice {
                        width: 100%;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}