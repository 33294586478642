.page-header {
    position: sticky;
    z-index: 200;
    background: #fff;
    border: 0;

    @include media-breakpoint-up(xs) {
        margin-bottom: 1.5rem;
        top: -33px;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 2.5rem;
        top: -49px;
    }

    .panel {
        background-color: #f2f2f2;

        .header {
            @include media-breakpoint-up(xs) {
                padding: .25rem 15px;
            }

            @include media-breakpoint-up(md) {
                padding: 0.5rem 20px;
            }
        }

        &:before,
        &:after {
            display: none !important;
        }

        .kiyoh-widget-plain {
            display: flex;
            align-items: center;
            font-size: 1.2rem;

            div:not([class]) {
                span {
                    &:first-child {
                        display: none;
                    }
                }
            }

            .rating-summary {
                margin: 0;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            a {
                color: #000;
                margin-left: 4px;
                text-decoration: underline;

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        .links {
            @include media-breakpoint-up(md) {
                display: block;
            }

            ul {
                padding: 0;
                margin: 0;
                display: flex;
                overflow: hidden;
                list-style: none;

                li {
                    margin-bottom: 0;

                    &:not(:first-child) {
                        margin-left: 2rem;
                    }

                    a {
                        color: #222;
                        font-size: 1.4rem;

                        &:hover,
                        &.active {
                            color: $secondary;
                        }
                    }
                }
            }
        }
    }

    .header {
        @include media-breakpoint-up(xs) {
            padding: .75rem 15px 0;
        }

        @include media-breakpoint-up(md) {
            padding: 1.5rem 20px;
        }

        &:before,
        &:after {
            display: none !important;
        }

        .logo {
            margin: 0;
            float: none;

            @include media-breakpoint-down(sm) {
                bottom: -5px;
                max-width: 130px;
            }

            @include media-breakpoint-up(sm) {
                bottom: -4px;
            }
        }

        .left-col {
            @include media-breakpoint-down(sm) {
                order: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 65%;
            }

            .nav-toggle {
                position: relative;
                left: 0;
                top: 0;
                width: 44px;
                height: 34px;
            }
        }

        .center-col {
            flex-grow: 1;

            @include media-breakpoint-down(sm) {
                order: 3;
                width: 100%;
            }

            @include media-breakpoint-up(md) {
                margin: 0 5rem;
            }

            .block-search {
                float: none;
                padding: 0;
                width: 100%;
                margin-top: 10px;

                @include media-breakpoint-up(md) {
                    max-width: 100%;
                }

                @include media-breakpoint-up(lg) {
                    max-width: 65%;
                    margin: 0 auto;
                }

                .label {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }

                .minisearch {
                    .control {
                        position: relative;

                        @include media-breakpoint-down(sm) {
                            margin: 0 -15px 0;
                            background-color: $secondary;
                            border: 0;
                        }

                        @include media-breakpoint-up(md) {
                            padding: 0;
                        }

                        input {
                            box-shadow: 0 1px 3px 0 rgba(96,96,96,0.2);

                            @include media-breakpoint-down(sm) {
                                position: relative;
                                left: 0;
                                border: 0;
                                margin: 10px 0;
                            }

                            &:focus {
                                border-color: $primary;
                            }
                        }

                        button {
                            padding: 0;
                            top: 12px;

                            @include media-breakpoint-down(sm) {
                                background-color: transparent;
                                border: 0;
                                top: 17px;
                                right: 25px;
                                display: block;
                                position: absolute;

                                i {
                                    width: 20px;
                                    height: 20px;
                                }
                            }

                            &:before {
                                display: none;
                            }
                        }
                    }

                    .input-text {
                        border: 1px solid #ced4da;
                        border-radius: .5rem;

                        @include media-breakpoint-down(sm) {
                            font-size: 1.4rem;
                        }

                        @include media-breakpoint-up(xs) {
                            height: calc(1.8em + .75rem + 2px);
                        }

                        @include media-breakpoint-up(md) {
                            height: calc(2.5em + .75rem + 2px);
                        }
                    }
                }
            }
        }

        .right-col {
            @include media-breakpoint-down(sm) {
                order: 2;
                align-items: center;
            }

            .cta-link {
                &:hover {
                    i {
                        background-color: $primary;
                    }

                    .label {
                        color: $primary;
                    }
                }

                .label {
                    font-size: 1.4rem;
                    color: #000;
                    margin-top: .5rem;
                    font-weight: 600;
                }
            }

            .minicart-wrapper {
                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                    margin-left: 1.5rem;
                }
            }
        }
    }

    .header-bottom {
        .nav-sections {
            @include media-breakpoint-down(sm) {
                position: fixed;
            }
        }
    }
}
