/* Row ultra USPs
========================================= */
.row-type-usps {
    position: relative;
    overflow: hidden;

    .slick-list {
        overflow: visible;
    }

    ul {
        background-color: #f2f2f2;
        padding: 1.5rem 0;
        margin: 0;
        list-style: none;

        @include media-breakpoint-down(sm) {
            line-height: 1.3;
            display: block;
        }

        @include media-breakpoint-up(xs) {
            flex-wrap: wrap;
        }

        li {
            margin: 0;

            @include media-breakpoint-down(sm) {
                padding: 0;
            }

            .inner {
                font-size: 1.3rem;
                display: flex;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    justify-content: center;
                }

                i {
                    margin-right: 1.5rem;
                }
            }
        }
    }
}

/* Featured producs slider
========================================= */
.row-type-featured-products {
    .slick-track {
        display: flex !important;
    }

    .slick-list {
        margin: 0 -10px;
    }

    .slick-slide {
        height: inherit !important;
        margin: 0 10px;

        > div {
            height: 100%;
        }
    }

    .slick-prev,
    .slick-next {
        background-color: $secondary;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        z-index: 1;
        transition: .3s ease;
        opacity: 0;
        pointer-events: none;
        border: 0;

        &:hover {
            background-color: darken($secondary, 10%);
        }

        &:before {
            display: none;
        }

        i {
            color: #fff;
        }
    }

    .slick-prev {
        left: -40px;
    }

    .slick-next {
        right: -40px;

        i {
            height: 14px;
        }
    }

    .block-title {
        strong {
            font-weight: 600;
        }
    }

    .block-products-list {
        margin: 0;
    }

    .products-grid {
        margin: 0;

        .product-items {
            overflow: hidden;

            .product-item {
                @include media-breakpoint-up(md) {
                    margin-bottom: 1rem;
                }

                .product-item-info {
                    border: 1px solid #eaeaea;

                    @include media-breakpoint-down(xs) {
                        display: flex;
                        align-items: center;
                    }

                    .product-item-details {
                        @include media-breakpoint-down(xs) {
                            padding: 1.5rem;
                        }
                    }

                    .product-image-container {
                        .product-image-wrapper {
                            @include media-breakpoint-down(xs) {
                                padding-bottom: 0 !important;
                                height: 150px;
                                width: 140px;
                            }

                            .product-image-photo {
                                @include media-breakpoint-down(xs) {
                                    position: relative;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .price-wrapper {
                        @include media-breakpoint-down(xs) {
                            margin-bottom: 1.5rem;
                        }
                    }

                    .product-item-actions {
                        .actions-primary {
                            @include media-breakpoint-down(xs) {
                                padding: 0 1.5rem;
                            }

                            form {
                                .tocart {
                                    @include media-breakpoint-down(xs) {
                                        width: 4.5rem;
                                        height: 4.5rem;
                                    }
                                }

                                .quantity-select {
                                    .input-text {
                                        @include media-breakpoint-down(xs) {
                                            width: 40px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .product-item-details {
                    @include media-breakpoint-down(xs) {
                        padding: 1.3rem;
                    }

                    .product-item-name {
                        @include media-breakpoint-down(xs) {
                            font-size: 1.4rem;
                            line-height: 1.4;
                        }
                    }
                }
            }

            &:hover {
                .slick-prev,
                .slick-next {
                    opacity: 1;
                    pointer-events: all;
                }

                .slick-prev {
                    left: 20px;
                }

                .slick-next {
                    right: 20px;
                }
            }
        }
    }
}

/* Brand slider
========================================= */
.row-type-brand-slider {
    .am-widget-brand-slider {
        .swiper-button-prev,
        .swiper-button-next {
            background-size: contain;

            @include media-breakpoint-down(sm) {
                width: 18px;
            }

            &:after {
                display: none;
            }
        }

        .swiper-slide {
            @include media-breakpoint-down(sm) {
                padding: 0;
            }

            .swiper-brand {
                @include media-breakpoint-down(sm) {
                    padding: 0;
                }

                .am-image {
                    @include media-breakpoint-down(sm) {
                        max-height: 60px;
                    }
                }
            }
        }

        .ambrands-slider-container {
            background-color: transparent;

            .amslider-header-container {
                padding: 0;
                background-color: transparent !important;
                font-size: $h5-font-size;
                font-weight: 600;
                text-align: left;
            }

            .ambrands-inner {
                padding: 0;

                button {
                    background-size: 50%;
                    background-color: $primary;

                    &.swiper-button-disabled {
                        background-color: $light;
                    }

                    &:hover {
                        background-color: $secondary;
                    }
                }
            }

            .ambrands-slider {
                max-width: 100% !important;
                margin-top: 0;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/* Service block
========================================= */
.row-type-service-block {
    .card-customer-service {
        .card-subtitle {
            color: #421d0b;
        }

        .team {
            @include media-breakpoint-down(xs) {
                left: -11rem;
                position: relative;
                max-height: 250px;
            }

            @include media-breakpoint-up(xs) {
                max-width: 200px;
                margin-top: 4rem;
                object-fit: contain;
                object-position: right bottom;
            }

            @include media-breakpoint-up(md) {
                margin-right: -4rem;
                margin-top: 2rem;
                object-fit: cover;
                object-position: left;
            }
        }

        .btn-group {
            .btn {
                @include media-breakpoint-down(sm) {
                    display: flex;
                    border-radius: .5rem;
                    background: #424242;
                    border-color: #424242;
                }
            }
        }
    }

    .card-reviews {
        .kiyoh-logo {
            width: 45px;
        }

        .btn {
            font-weight: 500;
            font-size: 1.3rem;

            &:visited {
                color: #fff;
            }
        }

        .kiyoh-widget-slider {
            margin-bottom: 0;
            flex: 1;
            display: flex;
            flex-direction: column;

            .kiyoh-widget-list {
                padding: 0;
            }

            .kiyoh-widget-item {
                margin-bottom: 0;

                .short {
                    height: auto;
                }

                .long {
                    line-height: 1.7;
                    max-height: 70px;
                    font-size: 1.3rem;
                }

                .name {
                    &:before {
                        content: "-";
                    }
                }
            }

            .kiyoh-widget-item-wrapper {
                background-color: transparent;
                box-shadow: none;
                padding: 0;

                .kiyoh-widget-item_top {
                    align-items: center;

                    span {
                        margin: 0;
                    }
                }
            }

            .kiyoh-widget-footer {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex: 1;
                margin: 0;
                font-size: 1.2rem;
                color: #000;

                a {
                    color: #000;
                }
            }
        }
    }
}

/* Hero image
========================================= */
.row-type-hero-image {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }

    img {
        object-fit: cover;
    }
}

/* Service blocks
========================================= */
.row-type-serivice-blocks {
    .row-type-serivice-blocks__inner {
        @include media-breakpoint-up(md) {
            margin-top: -10rem;
        }
        @include media-breakpoint-up(lg) {
            margin-top: -15rem;
        }

        h1 {
            font-weight: 600;
            line-height: 1;
        }

        .service-item {
            transition: .3s ease;

            &:hover {
                transform: translateY(-5px);
            }
        }

        .quick-links {
            img {
                width: 100%;
                height: 80px;
                object-fit: cover;
            }
        }

        .btn-link {
            color: $primary;
            font-size: 1.3rem;
            display: block;

            i {
                background-color: $primary;
            }
        }
    }
}

/* FAQ search
========================================= */
.row-type-faq-search {
    @include media-breakpoint-up(xs) {
        margin-top: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        margin-top: 5rem;
    }

    h2 {
        font-weight: 600;
    }

    .am-search-box-wrapper {
        .am-widget-center {
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }
    }

    .amfaq-search {
        margin: 0;

        .am-input {
            border: 1px solid #ced4da;
            border-radius: .5rem;
            box-shadow: 0 1px 3px 0 rgba(96, 96, 96, 0.2);
            height: calc(3em + .75rem + 2px);
        }

        .am-button {
            display: none;
        }

        .search-autocomplete {
            margin-top: -5px;
            border-radius: 0 0 .5rem .5rem;
            border-left: 1px solid #ced4da;
            border-right: 1px solid #ced4da;
            border-bottom: 1px solid #ced4da;
            box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.175);

            ul {
                li {
                    &:not(:empty) {
                        border: 0;
                        border-bottom: 1px solid #f2f2f2;
                    }
                    &:last-child {
                        border: 0;
                    }
                }
            }
        }
    }
}

/* FAQ categories
========================================= */
.row-type-faq-categories {
    .am-widget-categories-3 {
        margin: 0 -15px;
        display: block;

        @include media-breakpoint-up(md) {
            column-count: 2;
            column-gap: .2rem;
        }

        @include media-breakpoint-up(lg) {
            column-count: 3;
            column-gap: .2rem;
        }

        .am-widget-category {
            padding: 0 15px;
            width: 100%;
            break-inside: avoid;

            .amfaq_questions {
                display: flex;
                flex-flow: column;
            }

            .am-category-container {
                background-color: #fff;
                border-radius: $border-radius-lg;
                box-shadow: 0 1px 3px 0 rgba(96, 96, 96, 0.2);
                border: 1px solid #e3e5e8;

                @include media-breakpoint-up(xs) {
                    margin-bottom: 20px;
                    padding: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 30px;
                    padding: 2rem;
                }

                .am-category-header {
                    min-height: unset;
                }



                .view-all {
                    color: $secondary;
                    font-size: 1.3rem;
                }
            }
        }
    }
}

/* FAQ view
========================================= */
.faq-category-view,
.faq-question-view {
    .columns {
        .sidebar {
            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }

            @include media-breakpoint-up(md) {
                width: 20%;
            }

            .amfaq-search {
                display: none;
            }

            .amfaq_categories {
                background-color: $light;
                border: 0;
                overflow: hidden;
                border-radius: 0;

                .title {
                    display: none;
                }

                .item {
                    font-weight: 500;
                    padding: 0;
                    border-left: 3px solid transparent;

                    &:last-child {
                        border-radius: 0;
                    }

                    &:hover {
                        border-left-color: $secondary;
                    }

                    &.current {
                        border-left: 3px solid $secondary;
                        background-color: transparent;
                    }

                    a {
                        display: block;
                        color: #000;
                        padding: 1rem;
                    }
                }
            }
        }

        .column {
            @include media-breakpoint-up(md) {
                width: 80%;
            }

            .page-title-wrapper {
                margin-bottom: 2.5rem;
            }

            .ask-question-form {
                display: none;
            }

            .amfaq_questions {
                .am-item {
                    border: 0;
                    margin: 0;

                    @include media-breakpoint-up(xs) {
                        padding: .75rem;
                    }

                    @include media-breakpoint-up(md) {
                        padding: 1rem;
                    }

                    &:nth-child(odd) {
                        background: #f7f7f7;
                    }

                    .am-title {
                        margin: 0;
                        font-size: 1.4rem;
                    }
                }
            }

            .question_view {
                border: 0;
                margin: 0;
                padding: 0;
            }
        }
    }
}

/* FAQ
========================================= */
.amfaq_questions {
    .am-item {
        border: 0;
        margin: 0 0 .75rem 0;

        @include media-breakpoint-up(xs) {
            padding: .75rem;
        }

        @include media-breakpoint-up(md) {
            padding: 1rem;
        }

        &:nth-child(odd) {
            background: #f7f7f7;
        }

        .am-title {
            margin-bottom: 0;
            font-size: 1.5rem;
            line-height: 1.6;
            font-weight: 500;

            .am-minus,
            .am-plus {
                &:before {
                    font-size: 3.5rem;
                    color: #000;
                }
            }
        }

        .am-content {
            padding-bottom: 0;
            padding-top: 1rem;
            line-height: 1.7;

            .amfaq-question-footer-wrapper {
                margin-top: 1rem;
                justify-content: flex-end;

                .am-links {
                    padding: 0;

                    a {
                        color: $secondary;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}

.column.main {
    min-height: auto !important;
}
