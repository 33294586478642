.catalog-category-view,
.category-default-category,
.catalogsearch-result-index {
    &.page-products {
        .columns {
            z-index: unset;
        }
    }

    &.page-layout-2columns-left {
        .page-main {
            .column {
                &.main {
                    width: 80%;
                }
            }
        }
    }

    &.page-layout-1column {
        .subcategories-list {
            .card {
                transition: .3s ease;

                @include media-breakpoint-down(sm) {
                    border: 0;
                    border-bottom: 1px solid #eaeaea;
                }

                &.embed-responsive-1by1 {
                    &:before {
                        @include media-breakpoint-down(sm) {
                            padding-top: 75%;
                        }
                    }
                }

                &:nth-child(2n+1) {
                    @include media-breakpoint-down(sm) {
                        border-right: 1px solid #eaeaea;
                    }
                }

                &:hover {
                    box-shadow: 0 1px 10px 0 rgba(96, 96, 96, 0.2);
                }

                .img-thumb {
                    @include media-breakpoint-down(sm) {
                        padding-top: 1rem;
                        height: 70%;
                    }

                    @include media-breakpoint-up(xs) {
                        padding: 1rem 5rem 0;
                    }

                    @include media-breakpoint-up(md) {
                        padding: 0 5rem;
                    }
                }

                h3 {
                    @include media-breakpoint-up(xs) {
                        font-size: 1.4rem;
                        line-height: 1.2;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 1.8rem;
                        line-height: 1.4;
                    }
                }
            }

            .row {
                @include media-breakpoint-down(sm) {
                    padding: 0;
                }

                &>[class^="col-"] {
                    @include media-breakpoint-down(sm) {
                        padding: 0;
                    }
                }
            }
        }
    }

    .page-main {
        .sidebar-main {
            padding: 0;
        }

        .column {
            &.main {
                .breadcrumbs {
                    padding-left: 0;
                }

                .page-title-wrapper {
                    .page-title {
                        font-weight: 600;

                        @include media-breakpoint-up(xs) {
                            font-size: 2.2rem;
                        }

                        @include media-breakpoint-up(md) {
                            font-size: 2.8rem;
                        }
                    }
                }

                .category-short-description {
                    p {
                        color: #595959;

                        @include media-breakpoint-up(xs) {
                            line-height: 1.5;
                            font-size: 1.3rem;
                        }

                        @include media-breakpoint-up(md) {
                            line-height: 1.7;
                            font-size: 1.4rem;
                        }
                    }
                }

                .toolbar-products {
                    padding: 0;
                    margin-bottom: 1rem;

                    @include media-breakpoint-down(sm) {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 0;
                    }

                    .btn-filter {
                        @include media-breakpoint-down(sm) {
                            order: 1;
                            width: calc(50% - 5px);
                            background-color: transparent;
                            font-weight: 400;
                            border-radius: .3rem;

                            &:active {
                                box-shadow: none;
                            }
                        }
                    }

                    .toolbar-amount {
                        color: #7d7d7d;

                        @include media-breakpoint-up(xs) {
                            font-size: 1.2rem;
                        }

                        @include media-breakpoint-up(md) {
                            font-size: 1.3rem;
                        }

                        .toolbar-number {
                            font-weight: 600;
                        }

                        @include media-breakpoint-down(sm) {
                            order: 3;
                        }
                    }

                    .toolbar-sorter {
                        @include media-breakpoint-down(sm) {
                            order: 2;
                            width: calc(50% - 5px);
                            padding-top: 0;
                            margin-left: auto;
                        }

                        .sorter-label {
                            font-size: 1.3rem;
                            color: #7d7d7d;

                            @include media-breakpoint-down(sm) {
                                display: none;
                            }
                        }

                        .sorter-options {
                            border-radius: .3rem;
                            appearance: none;

                            @include media-breakpoint-down(sm) {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                            }
                        }

                        .sorter-action {
                            @include media-breakpoint-down(sm) {
                                display: none;
                            }
                        }

                        select {
                            &:focus {
                                box-shadow: none;
                            }

                            option {
                                font-weight: 500;
                            }
                        }

                        .limiter {
                            select {
                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .pages {
                        .pages-items {
                            display: flex;

                            .item {
                                .page {
                                    min-width: 4rem;
                                    height: 40px;
                                    background: #fff;
                                    border: 1px solid #dee0e3;
                                    color: #222423;
                                    font-weight: 400;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    &:hover {
                                        background-color: $light;
                                    }
                                }

                                &.current {
                                    .page {
                                        background-color: $light;
                                    }
                                }

                                &.pages-item-next {
                                    .next {
                                        min-width: 4rem;
                                        height: 40px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        &:before {
                                            margin: 0;
                                            font-size: 36px;
                                        }

                                        &:hover {
                                            background-color: $light;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .limiter {
                        font-size: 1.3rem;
                        color: #7d7d7d;
                    }
                }

                //Grid
                .products-grid {
                    margin-bottom: 0;

                    & ~ .toolbar-products  {
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 0;
                            justify-content: center;
                        }

                        .btn-filter {
                            display: none !important;
                        }
                    }

                    .product-items {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 0;

                        @include media-breakpoint-down(sm) {
                            margin: 1rem -15px 1.5rem;
                        }

                        @include media-breakpoint-up(md) {
                            margin: 0 -10px !important;
                        }
                    }
                }

                //List
                .products-list {
                    .product-items {
                        @include media-breakpoint-down(sm) {
                            margin: 0 -15px;
                        }
                    }
                }

                .category-description {
                    margin-bottom: 0;

                    @include media-breakpoint-up(xs) {
                        margin-top: 2rem;
                    }

                    @include media-breakpoint-up(md) {
                        margin-top: 3rem;
                    }

                    @include media-breakpoint-up(lg) {
                        column-count: 2;
                        column-gap: 30px;
                    }

                    p {
                        line-height: 1.7;
                        color: #595959;
                    }
                }
            }
        }
    }
}

.catalog-category-view {
    .category-view {
        .category-image {
            @include media-breakpoint-down(sm) {
                height: 100px;
                margin-left: -15px;
                margin-bottom: 10px;
            }

            @include media-breakpoint-up(xs) {
                width: calc(100% + 30px);
                height: 150px;
            }

            @include media-breakpoint-up(md) {
                width: 100%;
                height: 300px;
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
}

.ambrand-index-index {
    .category-view {
        .category-image {
            margin-bottom: 1rem;

            .image {
                max-height: 40px;
            }
        }
    }
}
