$primary:   #424242;
$secondary: #FE6720;
$light:     #f2f2f2;
$cta:       #34c053;
$cta-hover: #27b747;

$font-size-base:    1.4rem;
$h5-font-size:      1.8rem;
$h1-font-size:      3rem;

$paragraph-margin-bottom:   2rem;

$border-radius-sm:  .5rem;
$border-radius-lg:  1.25rem;

$card-border-color: $light;

// Container
$container-max-widths: (
    xl: 1270px
) ;

$breakpoints-query:
( xs ),
( sm ),
( md ),
( lg ),
( xl );

// embeds
$embed-responsive-aspect-ratios: ();
$embed-responsive-aspect-ratios: join(
  (
    (7 2),
    (9 2),
  ),
  $embed-responsive-aspect-ratios
);
