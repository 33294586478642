.modal-popup {
    &.confirm {
        .modal-header {
            display: none;
        }
    }

    &.filter-modal {
        .modal-inner-wrap {
            background-color: #fff !important;

            @include media-breakpoint-up(md) {
                width: 50%;
            }

            .modal-header {
                border: 0;
                padding-bottom: 0;

                .action-close {
                    position: relative;
                    padding: 0;
                    margin-left: auto;
                }
            }

            .modal-content {
                padding-top: 0;
            }
        }
    }

    .modal-inner-wrap {
        .modal-header {
            padding: 1.5rem;

            .modal-title {
                border: 0;
                padding: 0;
            }

            .action-close {
                padding: 1rem;
            }
        }

        .modal-content {
            border: 0;
            border-radius: 0;
            padding: 1.5rem;
        }

        .modal-footer {
            padding: 1.5rem;
            align-items: flex-start;
            justify-content: space-between;

            .action {                
                &.action-save-address {
                    margin: 0;
                }
            }

            .action-secondary {
                border: 0;
                border-radius: .5rem;
                color: #000;
                font-weight: 500;            
            }
    
            .action-primary {
                background-color: $secondary;
                border-color: $secondary;
                border-radius: .5rem;
    
                &:hover {
                    background-color: darken($secondary, 10%);
                    border-color: darken($secondary, 10%);
                }
            }            
        }
    }
}