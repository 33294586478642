#kiyoh-reviews {
    max-width: 950px;
    margin: 0 auto;

    .reviews-header {
        text-align: center;
    }

    .account-rating-container {
        .account-title {
            @include media-breakpoint-up(md) {
                display: flex;
            }

            .account-title_brand,
            .account-title_description {
                background-color: $white;
                box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
                display: flex;
                align-items: center;
                border-radius: 0.5rem;
            }

            .account-title_brand {
                padding: 1.5rem;

                @include media-breakpoint-between(xs,md) {
                    margin-bottom: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    margin-right: 2rem;
                }
            }

            .brand-logo {
                display: flex;
                align-items: center;

                .brand-logo-image {
                    align-items: center;
                    flex-shrink: 0;
                    margin-right: 2rem;
                    max-width: 52px;
                }

                .brand-logo-description {
                    > span {
                        color: #474546;
                        font-size: 1.6rem;
                        font-weight: bold;
                        margin-bottom: 0.4rem;
                    }

                    .rating-summary,
                    .account-title_brand-reviews {
                        display: none;
                    }

                    .account-title_brand-link {
                        a {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .account-title_description {
                padding: 1.5rem;
                flex: 1;
                justify-content: space-between;

                .account-title_description-text {
                    display: flex;
                    flex-direction: column;

                    b {
                        display: none;
                    }
                }

                .account-title_description-reviews {
                    > div {
                        display: flex;
                        align-items: center;
                    }

                    .header-rating_submit-review {
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .reviews-content {
        margin-top: 1.5rem;

        ul {
            padding: 0;
            list-style: none;

            li {
                background-color: $white;
                border: 1px solid rgba(0, 0, 0, 0.125);
                padding: 1.5rem;
                margin-bottom: 1.5rem;
                border-radius: 0.5rem;

                .review-sidebar {
                    float: right;

                    p {
                        margin-bottom: 0;
                        color: $text-muted;
                    }
                }

                .review-info_header {
                    span {
                        color: $text-muted;
                    }

                    p {
                        color: $body-color;
                    }
                }
            }
        }
    }

    .reviews-pagination {
        ul {
            margin: 0 -0.5rem;

            li {
                padding: 0 0.5rem;
                margin: 0;

                &.current {
                    strong {
                        padding: 0;
                    }
                }

                .action {
                    cursor: pointer;
                    margin: 0;
                }
            }
        }
    }
}
