.account {
    .sidebar {
        .nav {
            flex-flow: column;

            .item {
                display: block;
            }
        }
    }

    .column {
        form {
            legend {
                width: auto;
            }
        }
    }

    table {
        .col {
            width: auto;
        }
    }
}