.breadcrumbs {
    margin-bottom: 1rem;
    padding: 0;

    .items {
        .item {
            @include media-breakpoint-down(sm) {
                display: none;
            }

            &:nth-last-child(2) {
                @include media-breakpoint-down(sm) {
                    display: flex !important;
                    align-items: center;
                }

                a {
                    &:before {
                        @include media-breakpoint-down(sm) {
                            content: "";
                            mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='276.5px' height='512px' viewBox='0 0 276.5 512' style='enable-background:new 0 0 276.5 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M5.8,270.1l236.8,236.2c7.8,7.7,20.3,7.7,28.1,0c7.7-7.8,7.7-20.3,0-28.1L48,256L270.7,33.9c7.8-7.7,7.8-20.3,0-28.1 c-3.9-3.9-9-5.8-14.1-5.8c-5.1,0-10.1,1.9-14,5.8L5.8,242c-3.7,3.7-5.8,8.8-5.8,14S2.1,266.3,5.8,270.1z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                            width: 5px;
                            height: 10px;
                            mask-size: contain;
                            background: #000;
                            mask-repeat: no-repeat;
                            display: inline-block;
                            margin-right: 5px;
                        }
                    }
                }

                &:after {
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }

            strong {
                font-weight: 400;
            }
        }
    }
}
