/* BASE
======================================== */
body {
    font-display: swap;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

img {
    max-width: 100%;
    height: auto;
}

.navbar-brand {
    img {
        max-height: 100px;
        width: auto;
    }
}

p a {
    color: $secondary;

    &:visited {
        color: $secondary;
    }

    &:hover {
        color: darken($secondary, 10%) !important;
        text-decoration: underline;
    }
}

a {
    color: $secondary;

    &:visited {
        color: $secondary;
    }

    &:hover {
        text-decoration: none;
        color: darken($primary, 10%);
    }
}

::-moz-selection {
    color: #fff;
    background: $primary;
}

::selection {
    color: #fff;
    background: $primary;
}

.grecaptcha-badge {
    display: none !important;
}


video, iframe {
    max-width: 100% !important;
}
