.footer-top {
    ul {
        li {
            font-size: 1.3rem;
            display: flex;
            align-items: center;
        }
    }
}

.footer-main {
    @include media-breakpoint-down(sm) {
        .no-gutters-xs {
            margin-right: 0;
            margin-left: 0; 

            > .col,
            > [class*="col-"] {
              padding-right: 0;
              padding-left: 0;
            }                   
        }
    }

    .footer {
        @include media-breakpoint-down(sm) {
            padding-bottom: 0;
        }
    }

    .footer-main__inner {
        @include media-breakpoint-up(md) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
        }        
    }

    .customer-service-img {
        max-height: 320px;
    }

    .contact-item {
        &__icon {
            @include media-breakpoint-down(sm) {
                i {
                    width: 17px;
                    height: 17px;
                }
            }

            @include media-breakpoint-up(xs) {
                width: 30px;
                height: 30px;
            }

            @include media-breakpoint-up(md) {
                width: 40px;
                height: 40px;
            }
        }

        .flex-1 {
            flex: 1;
        }

        a {
            .svg-icon {
                width: 6px;
                height: 12px;                
            }
            
            &:hover {
                color: $primary !important;
            }
        }
    }

    .footer-menu {
        @include media-breakpoint-down(sm) {        
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        }

        h5 {
            @include media-breakpoint-down(sm) {
                font-size: 1.7rem;
            }

            i {
                transition: .3s ease;
            }

            &.open {
                i {
                    transform: rotate(90deg);
                }
            }

            ~ p {
                display: none;
            }
        }

        ul {
            @include media-breakpoint-down(sm) {
                display: none;
            }

            &.open {
                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }

            li {
                margin-bottom: .5rem;

                a {
                    color: #fff;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }
        }

        .ambrands-brandlist-widget {
            @include media-breakpoint-down(sm) {
                display: none;
            }        

            &.open {
                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }
                        
            .ambrands-letters-list {
                display: block;
            }

            .ambrands-letter {
                width: auto;
                margin: 0;

                .ambrands-title {
                    display: none;
                }

                .ambrands-content {
                    display: block;
                    margin: 0;

                    .ambrands-brand-item {
                        text-align: left;

                        &.-no-logo {
                            max-width: unset;
                        }

                        .ambrands-inner {
                            display: block;
                            background: transparent;
                            box-shadow: unset;
                            color: #fff;
                            border: 0;

                            .ambrands-label {
                                padding: 0;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }      

        .brand-images {
            display: block;
        }

        .brands-letter {
            width: auto;
            margin: 0;
            padding: 0;

            .letter {
                display: none;
            }
        }

        .brand-item {
            margin: 0;
            text-align: left;
            max-width: unset;
            min-width: unset;
            min-height: unset;            
        }

        .item {
            color: #fff;
            display: block;

            .am-image-wrapper {
                display: none;
            }

            .label {
                margin: 0;
                padding: 0;

                &:before {
                    display: none;
                }
            }            
        }
    }
}

.footer-bottom {
    .payment-methods {
        margin: 0 -.75rem;
        
        li {
            padding: 0 .75rem;

            img {
                width: 40px;
            }
        }
    }
}