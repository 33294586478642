.page-products {
    .products-grid {
        .product-items {
            .product-item {
                .product-item-info {
                    display: flex;
                    flex-direction: column;
                }

                .product-item-details {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;

                    .product-item-name {
                        margin-bottom: auto;
                    }
                }
            }
        }
    }
}

//Grid
.products-grid {
    .product-items {
        .product-item {
            @include media-breakpoint-up(xs) {
                width: 50%;
            }

            @include media-breakpoint-up(lg) {
                width: 33.333%;
            }

            @include media-breakpoint-up(md) {
                padding: 0 10px;
                margin-bottom: 20px;
            }

            &:nth-child(2n+1) {
                .product-item-info {
                    border-right: 1px solid #eaeaea;
                }
            }

            .product-item-info {
                @include media-breakpoint-up(xs) {
                    border-bottom: 1px solid #eaeaea;
                }

                @include media-breakpoint-up(md) {
                    border: 1px solid #eaeaea;
                }

                .product-item-details {
                    @include media-breakpoint-up(xs) {
                        padding: 0 1rem;
                    }

                    @include media-breakpoint-up(md) {
                        padding: 0 1.5rem;
                    }

                    .product-item-name {
                        @include media-breakpoint-up(xs) {
                            font-size: 1.3rem;
                        }

                        @include media-breakpoint-up(md) {
                            font-size: 1.5rem;
                        }
                    }
                }

                .product-item-actions {
                    .actions-primary {
                        padding: 0 1.5rem 1.5rem;

                        form {
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
    }
}

//List
.products-list {
    .product-items {
        .product-item {
            display: block;

            .product-item-info {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #eaeaea;

                .product-item-inner {
                    display: flex;
                    flex-direction: column;

                    .product-item-actions {
                        order: 2;
                    }

                    .product-item-description {
                        order: 1;
                        margin-top: 0;
                        margin-bottom: 1rem;

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }

                        p {
                            margin-bottom: 0;
                            color: #595959;
                        }
                    }
                }

                .product-item-details {
                    padding: 1.5rem;

                    .product-item-name {
                        margin-top: 0;
                    }

                    .price-wrapper {
                        margin-bottom: 1rem;
                    }

                    .actions-primary {
                        padding: 0 1.5rem 1.5rem;
                        padding-bottom: 0;

                        form {
                            justify-content: flex-end;

                            .tocart {
                                margin-left: 2rem;
                            }
                        }
                    }
                }

                .amshopby-option-link {
                    right: unset;
                    left: 15px;
                    bottom: 15px;
                }
            }

            .product-item-photo {
                width: 25%;
                padding: 0;
                object-fit: contain;
            }

            .product-item-details {
                width: 75%;

                .product-item-name {
                    @include media-breakpoint-up(xs) {
                        font-size: 1.5rem;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}

.products {
    margin-bottom: 2rem;

    .product-items {
        .product-item {
            margin-left: 0 !important;

            .product-item-info {
                width: 100%;
                height: 100%;
                position: relative;
                transition: .3s ease;
                background-color: #fff;

                &:hover {
                    box-shadow: 0 1px 10px 0 rgba(96, 96, 96, 0.2);
                }

                .badge-label {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    z-index: 2;
                    font-weight: 600;
                    pointer-events: none;

                    @include media-breakpoint-up(md) {
                        font-size: 1.1rem;
                    }
                }

                .product-image-container {
                    width: 100% !important;
                    aspect-ratio: unset !important;

                    .product-image-wrapper {
                        padding-bottom: 0 !important;
                        height: auto;

                        .product-image-photo {
                            position: relative;
                            width: auto;

                            @include media-breakpoint-up(xs) {
                                height: 120px;
                            }

                            @include media-breakpoint-up(md) {
                                height: 180px;
                            }
                        }
                    }
                }

                .product-item-details {
                    .product-item-name {
                        font-weight: 500;

                        .product-item-link {
                            font-weight: 500;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .amshopby-option-link {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    z-index: 1;
                    margin: 0;
                    left: unset;
                    bottom: unset;

                    a {
                        display: inline-block;
                        margin: 0;

                        img {
                            margin: 0;
                            max-height: 40px;
                        }
                    }
                }

                .price-wrapper {
                    @include media-breakpoint-up(xs) {
                        margin-bottom: 1rem;
                    }

                    @include media-breakpoint-up(md) {
                        margin-bottom: 1.5rem;
                    }

                    .price-box {
                        margin-bottom: 0;

                        .price-label {
                            display: none;
                        }

                        .minimal-price-link {
                            display: none;
                        }

                        .special-price__wrapper {
                            .price-wrapper {
                                margin: 0;
                            }

                            .old-price {
                                color: #999;
                                font-size: 1.3rem;
                                margin-left: .5rem;
                            }
                        }

                        .price {
                            margin-left: -4px;

                            @include media-breakpoint-up(xs) {
                                font-size: 1.3rem;
                            }

                            @include media-breakpoint-up(md) {
                                font-size: 1.5rem;
                            }
                        }
                    }

                    strong {
                        @include media-breakpoint-down(sm) {
                            font-size: 1.3rem;
                        }
                    }
                }

                .product-item-actions {
                    margin: 0 -1.5rem;

                    .actions-primary {
                        display: block;

                        form {
                            display: flex;
                            align-items: flex-end;

                            .quantity-select {
                                label {
                                    font-weight: 500;
                                    font-size: 1.2rem;
                                }

                                .minus,
                                .plus {
                                    background-color: #fff;
                                    border-color: #e8e8e8;
                                    padding: 0;
                                    width: 35px;
                                    height: 35px;
                                    font-weight: 400;

                                    &:hover {
                                        background-color: #f7f7f7;
                                    }

                                    &:active {
                                        box-shadow: none;
                                    }
                                }

                                .input-text {
                                    height: auto;
                                    border-left: 0;
                                    border-right: 0;
                                    border: 1px solid #e8e8e8;
                                    margin: 0 .5rem;
                                    font-weight: 400;
                                    width: 40px;

                                    &:focus {
                                        box-shadow: none;
                                        border-color: $secondary;
                                    }
                                }
                            }

                            .tocart {
                                background-color: $secondary;
                                border-color: $secondary;
                                border-radius: .5rem;
                                padding: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: inset 0 -2px 0 0 rgba(0,0,0,0.20);
                                border: 0;

                                &:hover {
                                    background-color: #CB521A;
                                }

                                @include media-breakpoint-up(xs) {
                                    width: 3.5rem;
                                    height: 3.5rem;
                                }

                                @include media-breakpoint-up(md) {
                                    width: 5rem;
                                    height: 5rem;
                                }

                                i {
                                    background-color: #fff;

                                    @include media-breakpoint-up(xs) {
                                        width: 18px;
                                        height: 16px;
                                    }

                                    @include media-breakpoint-up(md) {
                                        width: 23px;
                                        height: 20px;
                                    }
                                }
                            }
                        }

                        .stock {
                            &.unavailable {
                                color: #e30a0a;
                                position: relative;
                                display: flex;
                                align-items: center;

                                &:after {
                                    content: "";
                                    width: 5px;
                                    height: 5px;
                                    border-radius: 100%;
                                    background: #e30a0a;
                                    display: inline-block;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
