.header-bottom {
    .minicart-wrapper {
        margin-left: 2rem;

        .showcart {
            display: flex;
            align-items: center;
            flex-direction: column;

            &:before {
                display: none !important;
            }

            .label {
                font-size: 1.3rem;
                color: #000;
                margin-top: .5rem;
            }

            .counter {
                &.qty {
                    background-color: $secondary;
                    position: absolute;
                    border-radius: 20px;
                    color: #fff;
                    min-width: 21px;
                    height: 21px;
                    padding: 3px;
                    margin: 0;
                    font-size: 1.1rem;                    
                    top: -12px;
                    border: 2px solid #fff;         
                    font-weight: 600;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include media-breakpoint-up(xs) {
                        right: -10px;
                    }

                    @include media-breakpoint-up(md) {
                        right: 20px;
                    }                    
                }
            }
        }    

        .block-minicart {
            border-radius: .5rem;
            box-shadow: 0 1px 3px 0 rgba(96, 96, 96, 0.2);
            max-height: calc(100vh - 140px);
            overflow-y: auto;
            padding: 20px;

            &:before {
                right: 50px;
            }

            &:after {
                right: 49px;
            }

            #top-cart-btn-checkout {
                background-color: $cta;
                border-color: $cta;
                border-radius: .5rem;

                &:hover {
                    background-color: $cta-hover;
                    border-color: $cta-hover;
                }
            }

            #minicart-content-wrapper {
                .subtitle {
                    &.empty {
                        padding: 0;
                        font-weight: 500;
                        font-size: 1.3rem;
                    }
                }

                .minicart-items-wrapper {
                    .minicart-items {
                        .product-item {
                            a {
                                color: #000;
                                font-weight: 500;
                            }
                        }
                    }
                }

                .viewcart {
                    display: inline-block;
                    padding: .75rem 1.5rem;
                    background-color: $light;
                    border-radius: .5rem;
                    color: #000;
                    font-weight: 500;

                    &:hover {
                        background-color: #dfdcdc;
                    }
                }
            }
        }

        .minicart-widgets {
            margin: 0;
        }
    }
}

.modal-popup {
    &.confirm {
        .modal-content {
            padding: 1.5rem;
            border: 0;  
        } 
    }
}