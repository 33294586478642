.page-main {
    .page-title {
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2.2rem;
    }

    p {
        line-height: 1.7;
    }

    strong {
        font-weight: 600;
    }

    ol, ul, dl {
        margin-bottom: 2rem;
        padding-left: 15px;
    }

    ul, ol {
        > li {
            margin-bottom: .5rem;
        }
    }
}


.cms-page-view {
    .page-header {
        position: relative;
        top: unset;
    }
}

/* Home
========================================= */
.cms-home {
    .page-main {
        .columns {
            @include media-breakpoint-up(xs) {
                margin-bottom: 2rem;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 5rem; 
            }

            input {
                display: none;

                &:checked {
                    & ~ {
                        .read-more-btn {
                            span {
                                display: none;
                            }

                            label {
                                &:before {
                                    content: 'Lees minder';
                                }

                                i {
                                    transform: rotate(-90deg);
                                }
                            }
                        }
                    }

                    & ~ .content-toggle {
                        height: auto;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
            
            .content-toggle {                
                overflow: hidden;
                position: relative; 

                @include media-breakpoint-up(xs) {
                    height: 25rem;
                }
                
                @include media-breakpoint-up(md) {
                    height: 35rem;
                    columns: 2;
                    column-gap: 3rem;   
                }

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(rgba(255,255,255,0), #fff);
                    content: '';                
                }                
            }

            .read-more-btn {
                position: relative;
                z-index: 1;

                label {
                    font-weight: 600;
                    font-size: 1.5rem;

                    i {
                        background-color: #000;
                        transform: rotate(90deg);
                        margin-left: 10px;                    
                    }                    
                }
            }
        }

        .main {
            padding: 0;

            h1 {
                font-size: 2.5rem;
            }

            h2 {
                font-size: 2rem;
            }

            h3 {
                font-size: 1.7rem;
            }   
            
            h4 {
                font-size: 1.6rem;
            }

            h5 {
                font-size: 1.5rem;
            }

            ul,ol {
                padding-left: 15px;
            }
        }
    }

    .page-bottom {
        @include media-breakpoint-up(xs) {
            margin-bottom: 2rem;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 5rem;
        }
    }
}

/* Brand page
========================================= */
.cms-merk {
    .page-main {
        .am-widget-brandlist {
            .brand-images {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin: 0 -15px;

                .letter {
                    display: none;
                    margin: 0;
                }

                .brands-letter {                    
                    padding: 0 15px !important;                    

                    @include media-breakpoint-up(xs) {
                        width: 50%;
                        margin-bottom: 1.5rem;
                    }

                    @include media-breakpoint-up(md) {
                        width: 25%;
                        margin-bottom: 3rem;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 20%;
                    }

                    dd {
                        margin: 0;
                    }

                    .bare-list {
                        justify-content: center;
                        margin: 0;

                        .ambrands-image {
                            max-width: 140px;
                            max-height: 70px;
                        }
                    }
                }

                .brand-item {
                    margin: 0;
                    width: 100%;
                    max-width: unset;
                    border: 1px solid $light;
                    transition: .3s ease;

                    &:hover {
                        box-shadow: 0 1px 10px 0 rgba(96, 96, 96, 0.2);
                    }

                    .item {
                        padding: 1rem;

                        .label {
                            margin: 0;
                            padding: 0;
                            font-weight: 500;
        
                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Contact page
========================================= */
.contact-index-index {
    #contact-form {
        min-width: unset;
        width: 100%;

        .fieldset {
            .legend {
                margin: 0 0 1rem 0;
                font-weight: 600;
            }

            .note {
                &:before {
                    display: none;
                }
            }
        }
    }

    .contact-details {
        strong {
            display: inline-block;
            min-width: 75px;
        }

        a {
            color: #fff;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .social-media {
        i {
            font-size: 2rem;
        }
    }
}

/* Page: Customer service
========================================= */
.page-layout-customer-service {
    .page-header {
        margin: 0;
    }    
}

/* Page: 404
========================================= */
.page-layout-404 {
    .page-header {
        margin-bottom: 0;
    }

    .page-title-wrapper {
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .page-main {        
        position: relative;
        @extend .rounded-lg;

        @include media-breakpoint-up(xs) {
            padding: 2rem 15px;
        }

        @include media-breakpoint-up(md) {
            padding: 3rem;
            background-color: #fff;     
            margin-top: -10rem;            
            max-width: 65vw;                   
        }

        @include media-breakpoint-up(lg) {
            max-width: 55vw;
        }

        @include media-breakpoint-up(xl) {
            margin-top: -15rem;
            max-width: 45vw;
        }   
        
        .main {
            dl {
                padding: 0;
            }
        }
    }
}