.email-template {
    .header {
        text-align: center;
    }
}

.wrapper {
    .button {
        .inner-wrapper { 
            tr {
                td {
                    background-color: $primary !important; 
                    a {
                        color: #ffffff; 
                        text-decoration: none;
                        border: 1px solid transparent; 
                    }
                    &:hover { 
                        background-color: #f78f1f;  
                    }
                }
            } 
        }
    }
}