.cart-container {
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
    }

    table {
        th,
        td {
            border-top: 0;
        }
    }

    .cart-summary {
        padding: 1px 15px 15px;
        top: unset !important;

        @include media-breakpoint-down(md) {
            order: 2;
        }

        @include media-breakpoint-up(md) {
            width: 30%;
        }

        .title {
            font-weight: 500;
        }

        .cart-totals {
            padding-top: 0;
            border: 0;

            .data {
                tr {
                    .mark {
                        padding-left: 0;
                        background-color: transparent;
                    }
                }
            }

            .grand {
                &.totals {
                    &.incl {
                        .price {
                            font-size: 1.7rem;
                        }
                    }

                    &.excl {
                        display: none;
                    }
                }
            }
        }

        #block-discount {
            #block-discount-heading {
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }

    .form-cart {
        @include media-breakpoint-down(md) {
            order: 1;
        }

        @include media-breakpoint-up(md) {
            width: 67%;
        }

        #shopping-cart-table {
            background-color: #fff;
            border: 1px solid #eaeaea;

            thead {
                background-color: $light;

                tr {
                    th {
                        padding: 0.75rem;
                        font-weight: 600;
                        border-bottom: 0;
                    }
                }
            }

            tbody {
                &.item {
                    padding: 15px;

                    .product-image-photo {
                        @include media-breakpoint-up(xs) {
                            height: 50px;
                        }

                        @include media-breakpoint-up(lg) {
                            height: 100px;
                        }
                    }

                    .item-actions {
                        td {
                            padding-top: 0;
                        }

                        .actions-toolbar {
                            justify-content: flex-end;

                            .action {
                                margin-bottom: 0;
                            }

                            .action-edit {
                                display: none;
                            }

                            .action-delete {
                                padding: 0;
                                background-color: transparent;
                                border: 0;
                                color: #aaa;
                                font-weight: 600;
                                font-size: 1.3rem;

                                &:hover {
                                    color: $secondary;
                                }

                                &:active {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .subtotal {
                        min-width: 110px;
                    }

                    td {
                        &.price,
                        &.subtotal {
                            .price-including-tax {
                                .price {
                                    font-weight: 600;
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }

            .table-caption {
                display: none;
            }
        }
    }
}

.crosssell {
    .products-grid {
        .product-items {
            padding: 0;
            margin: 0 -10px;

            .product-item {
                @include media-breakpoint-up(lg) {
                    width: 25%;
                }
            }
        }
    }
}
