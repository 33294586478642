/* COOKIE PREFERENCES
======================================== */
#notice-cookie-block {
    position: fixed;
    z-index: 99999999;
    bottom: 15px;
    right: 15px;
    left: unset;
    max-width: 40rem;
    background-color: rgba($secondary, .9);
    backdrop-filter: blur(10px);
    padding: 2.5rem;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow;

    .content {
        &:before {
            content: "🍪 Cookies";
            color: #fff;
            font-weight: bold;
            font-size: 1.9rem;
            margin-bottom: 1rem;
            display: block;            
        }

        p {
            color: #fff;
            line-height: 1.4;
        }

        strong {
            display: block;
            font-size: 1.4rem;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    .actions {
        margin-top: 1rem;

        #btn-cookie-allow {
            @extend .btn;
            @extend .btn-primary;
            padding: .75rem 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        left: 15px;

        max-width: calc(100% - 30px);

        p {
            font-size: 95%;
        }
    }
}