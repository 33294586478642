.message {
    border-radius: .3rem;
    font-weight: 500;
    font-size: 1.4rem;

    @include media-breakpoint-up(xs) {
        padding: 1rem 1.5rem !important;
    }

    @include media-breakpoint-up(md) {
        padding: 2.5rem 3rem !important;
    }

    a {
        color: #000;

        &:hover {
            text-decoration: underline;
        }
    }

    &.info {
        color: #856404;
        background-color: #fdd34e;
        border-color: #fdd34e;

        & > :first-child {
            &:before {
                display: none
            }
        }
    }

    &.success {
        background-color: #a3e8a3;
        color: #034603;

        & > :first-child {
            &:before {
                content: "";
                mask-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='512px' height='376px' viewBox='0 0 512 376' style='enable-background:new 0 0 512 376;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M504.5,7.5c-10-10-26.2-10-36.2,0L161.6,314.2L43.7,196.3c-10-10-26.2-10-36.2,0c-10,10-10,26.2,0,36.2l136,136 c10,10,26.2,10,36.2,0L504.5,43.7C514.5,33.7,514.5,17.5,504.5,7.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                width: 18px;
                height: 14px;
                mask-size: contain;
                background: #034603;
                margin-right: .5rem;
                margin-top: 0;
                position: relative;
                top: -2px;
            }
        }
    }

    &.error,
    &.warning {
        & > :first-child {
            &:before {
                width: 25px;
                position: relative;
                margin: 0 0 0 -5px;
                top: 0;
            }
        }
    }
}