.sidebar-main {
    .filter {
        .filter-subtitle {
            display: none;
        }

        .filter-current {
            .filter-current-subtitle {
                display: none;
            }

            .items {
                padding: 0;
            }
        }

        .filter-actions {
            .filter-clear {
                color: #000;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .filter-options {
            padding: 0;

            .filter-options-title {
                .tooltip-attr {
                    display: flex;

                    &:hover {
                        i {
                            background-color: #000;
                        }
                    }
                }
            }

            .filter-options-content {
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #eaeaea;
                }

                .am-filter-items-attr_price {
                    @include media-breakpoint-down(sm) {
                        max-width: unset;
                    }
                }

                .swatch-option {
                    &.selected + .am-shopby-swatch-label {
                        color: $secondary;
                    }
                }

                .filter-tooltip {
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    position: absolute;
                    right: 0;
                    top: -25px;

                    &:active {
                        box-shadow: none;
                    }
                }

                .items {
                    margin: 0;
                    padding: 0;

                    .item {
                        a {
                            color: #000;

                            @include media-breakpoint-down(sm) {
                                padding-left: 25px;
                            }

                            &:hover {
                                color: $secondary;

                                ~ .amshopby-choice {
                                    &:before,
                                    &:hover {
                                        border-color: $secondary;
                                    }
                                }

                                .count {
                                    color: $secondary;
                                }
                            }
                        }

                        .amshopby-choice {
                            &:before {
                                border-color: #e3e3e3;
                            }

                            &:hover {
                                &:before {
                                    border-color: $secondary;
                                }
                            }
                        }
                    }

                    input[type="checkbox"] {
                        + .amshopby-choice {
                            &:after {
                                background-color: $secondary;
                                width: 15px;
                                height: 15px;
                                top: -2px;

                                @include media-breakpoint-down(sm) {
                                    background-size: 12px;
                                    background-repeat: no-repeat;
                                }
                            }

                            &:before {
                                width: 15px;
                                height: 15px;
                                top: -2px;
                            }

                            &:checked {
                                &:before {
                                    border-color: $secondary;
                                }
                            }
                        }
                    }
                }

                .am-show-more {
                    letter-spacing: 0;
                    color: $secondary;
                    padding-left: 0;
                    font-size: 1.3rem;
                    -webkit-appearance: none;
                    border: 0;
                    background-color: transparent;

                    &:before {
                        @include media-breakpoint-down(sm) {
                            width: 13px;
                        }
                    }

                    .am-counter {
                        opacity: 1;
                        font-weight: 500;
                        padding: 0;
                    }

                    &:hover {
                        color: darken($secondary, 15%);
                    }
                }

                .amshopby-slider-wrapper {
                    .amshopby-slider-container {
                        .am-slider {
                            .ui-slider-handle {
                                background-color: $secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-main {
    .amasty-catalog-topnav {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .catalog-category-view,
    .catalogsearch-result-index {
        &.page-with-filter {
            .sidebar-main {
                position: fixed;
                transform: translateX(100%);
                width: 80%;
                background: #fff;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 300;
                transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
                overflow: auto;
                padding: 15px;

                .filter-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &:after {
                        content: "";
                        mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='329px' height='329px' viewBox='0 0 329 329' style='enable-background:new 0 0 329 329;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M194.6,164.8L322.7,36.7c8.3-8.3,8.3-21.8,0-30.1c-8.3-8.3-21.8-8.3-30.1,0L164.5,134.6L36.4,6.5 c-8.3-8.3-21.8-8.3-30.1,0c-8.3,8.3-8.3,21.8,0,30.1l128.1,128.1L6.3,292.9c-8.3,8.3-8.3,21.8,0,30.1c4.2,4.2,9.6,6.2,15.1,6.2 s10.9-2.1,15.1-6.2l128.1-128.1L292.6,323c4.2,4.2,9.6,6.2,15.1,6.2c5.5,0,10.9-2.1,15.1-6.2c8.3-8.3,8.3-21.8,0-30.1L194.6,164.8z' /%3E%3C/svg%3E%0A");
                        background-color: #000;
                        mask-size: contain;
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                    }
                }
            }

            &.filters-open {
                overflow: hidden;

                .sidebar-main {
                    transform: translateX(0);
                    box-shadow: 0 0px 0px 500px rgba(0, 0, 0, 0.6);
                }
            }
        }
    }
}
