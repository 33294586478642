.page-layout-checkout {
    background-color: $light;

    .logo {
        float: none;
        display: block;

        @include media-breakpoint-between(xs,md) {
            max-width: unset;
            background: #fff;
            padding: 10px 15px;
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-top: 0;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid #dee0e3;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 25px;
        }

        img {
            @include media-breakpoint-between(xs,md) {
                max-width: 130px;
            }
        }
    }

    .page-title-wrapper {
        position: relative;
        height: auto;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-bottom: 1.5rem;
        }

        .page-title {
            @include media-breakpoint-between(xs, sm) {
                font-size: 2rem;
            }
        }
    }

    .opc-progress-bar {
        padding: 0;
        margin: 0 0 2rem 0;

        @include media-breakpoint-up(xs) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            width: calc(100% - 450px);
        }

        @include media-breakpoint-up(xl) {
            width: calc(100% - 580px);
        }

        @media only screen and (min-width: 1400px) {
            width: calc(100% - 560px);
        }

        @media only screen and (min-width: 1600px) {
            width: calc(100% - 440px);
        }

        .opc-progress-bar-item {
            width: 33.333%;

            &:before {
                height: 3px;
            }

            span {
                font-weight: 600;
                white-space: nowrap;

                @include media-breakpoint-up(xs) {
                    font-size: 1.3rem;
                }

                @include media-breakpoint-up(md) {
                    font-size: 1.5rem;
                }

                &:after {
                    content: counter(i);
                    counter-increment: i;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    font-size: 1.4rem;
                    font-family: 'Poppins', sans-serif;
                    height: 32px;
                    margin-left: -16px;
                    top: 3px;
                    width: 32px;
                }
            }

            &._active {
                content: unset;
            }
        }
    }

    .opc-estimated-wrapper {
        border-bottom: 0;
        margin: 0;
        padding: 0;

        @include media-breakpoint-down(md) {
            display: block;
        }

        .estimated-block {
            display: none;
        }

        .minicart-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 9;
            margin: 0;

            button {
                background-color: transparent;
                border: 0;
                height: 57px;
                position: relative;

                &:before {
                    mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='shopping_cart' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='512px' height='456.8px' viewBox='0 0 512 456.8' style='enable-background:new 0 0 512 456.8;' xml:space='preserve'%3E%3Cpath d='M148,351.7c-22.4-1.1-21.9-32.6,0-33.7c62.2,0.2,226.1,0.1,290.3,0.1c17.5,0,32.6-11.9,36.5-29l36.4-157.7 c2.1-9,0-18.4-5.8-25.6c-5.8-7.3-14.4-11.5-23.6-11.5c-25.8,0-244.8-1-357-1.4l-17.8-66C102.7,11,88.3,0,72,0H15C6.7,0,0,6.7,0,15 s6.7,15,15,15h57c2.8,0,5.3,1.9,6,4.6L146.3,288c-12,0.4-23.2,5.3-31.7,13.9c-8.9,9-13.7,21-13.5,33.7c0.4,25.4,21.5,46,46.9,46 h21.9c-3.3,6.8-5.1,14.5-5.1,22.5c0,29,23.6,52.5,52.5,52.5s52.5-23.6,52.5-52.5c0-8.1-1.8-15.7-5.1-22.5h94.8 c-3.2,6.8-5.1,14.5-5.1,22.5c0,29,23.6,52.5,52.5,52.5c29,0,52.5-23.6,52.5-52.5c0-8.4-2-16.3-5.5-23.3c5.9-2,10.2-7.6,10.2-14.2 c0-8.3-6.7-15-15-15L148,351.7z M239.9,404.2c0,12.4-10.1,22.5-22.5,22.5s-22.5-10.1-22.5-22.5c0-12.4,10.1-22.5,22.5-22.5h0 C229.8,381.7,239.9,391.8,239.9,404.2z M407,426.7c-12.4,0-22.5-10.1-22.5-22.5c0-12.3,9.9-22.3,22.1-22.5h0.7 c12.2,0.2,22.1,10.2,22.1,22.5C429.5,416.6,419.4,426.7,407,426.7L407,426.7z M481.9,124.3c0.1,0.1,0.1,0.2,0.1,0.3L466.6,191h-52 l8.8-67.1l58.2,0.2C481.8,124.2,481.8,124.2,481.9,124.3L481.9,124.3z M327.2,288.1v-67h53.2l-8.8,67H327.2z M252.8,288.1l-8.9-67 h53.3v67H252.8z M183.7,288.1L183.7,288.1c-3.7,0-6.9-2.5-7.9-6l-16.5-61h54.2l8.9,67H183.7z M239.9,191l-9-67.8l66.3,0.3V191 L239.9,191z M327.2,191v-67.4l66.1,0.3l-8.9,67.2H327.2z M200.5,123.1l9,68h-58.3l-18.4-68.2L200.5,123.1z M438.3,288.1h-36.4 l8.8-67h49l-14.1,61.2C444.8,285.7,441.8,288.1,438.3,288.1z'/%3E%3C/svg%3E%0A");        width: 22px;
                    background-color: #000;
                    width: 30px;
                    height: 27px;
                    mask-size: contain;
                    mask-repeat: no-repeat;
                }

                .counter {
                    &.qty {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        border-radius: 100%;
                        min-width: 21px;
                        height: 21px;
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        border: 2px solid #fff;
                        margin: 0;
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }

    .opc-sidebar {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        overflow-y: auto;
        margin-top: 0;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            width: 450px;
            border-left: 1px solid #dee0e3;
            background-color: #fff;
        }

        @include media-breakpoint-up(xl) {
            width: 580px;
        }

        @include media-breakpoint-down(md) {
            visibility: hidden;
            min-width: 0;
            transition: visibility 0s .3s,opacity .3s ease;
            opacity: 0;
        }

        &._show {
            opacity: 1;
            visibility: visible;
            transition: opacity .3s ease;
        }

        .modal-inner-wrap {
            background-color: #fff;
        }

        .modal-header {
            display: none;
        }

        .modal-content {
            border-radius: 0;
            border: 0;
        }

        .opc-block-summary {
            background-color: #fff;

            @include media-breakpoint-up(xs) {
                padding: 1.5rem;
            }

            @include media-breakpoint-up(md) {
                padding: 3rem;
            }

            > .title {
                font-weight: 600;
                font-size: 1.9rem;
                border: 0;
            }

            .items-in-cart {
                border: 0;

                .title {
                    border: 0;

                    &:after {
                        width: 20px;
                    }

                    strong {
                        font-size: 1.4rem;
                        font-weight: 500;
                    }
                }

                .minicart-items {
                    padding: 0;
                    margin: 0;

                    .product-item {
                        .product {
                            .product-item-name-block {
                                padding-right: 1.5rem;
                            }

                            .subtotal {
                                .price-including-tax {
                                    .price {
                                        font-size: 1.4rem;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .table-totals {
                margin-bottom: 0;

                .mark {
                    background-color: transparent;
                }

                .totals {
                    &.incl {
                        strong {
                            font-weight: 600;

                            @include media-breakpoint-up(xs) {
                                font-size: 1.6rem;
                            }

                            @include media-breakpoint-up(md) {
                                font-size: 1.8rem;
                            }
                        }
                    }

                    &.excl {
                        display: none;
                    }

                    &.grand {
                        border-top: 1px solid $light;

                        th,
                        td {
                            padding-top: 1rem;
                        }
                    }
                }

                .totals-tax {
                    color: #a6a6a6;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .opc-wrapper {
        padding-right: 0;

        @include media-breakpoint-up(xs) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            width: calc(100% - 450px);
        }

        @include media-breakpoint-up(xl) {
            width: calc(100% - 580px);
        }

        @media only screen and (min-width: 1400px) {
            width: calc(100% - 560px);
        }

        @media only screen and (min-width: 1600px) {
            width: calc(100% - 440px);
        }

        .step-content {
            margin: 0;
        }

        .form-login,
        .form-shipping-address {
            max-width: 100%;
            margin-top: 1rem;
            border: 0;
        }

        .form {
            .field {
                label {
                    &.label {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                }

                .control {
                    &._with-tooltip {
                        input {
                            margin-right: 0;
                            width: 100%;
                        }

                        .field-tooltip {
                            display: none;
                        }
                    }

                    span {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        .opc {
            li {
                background-color: #fff;
                border: 1px solid $light;
                @extend .shadow-sm;

                &.checkout-shipping-method {
                    margin-top: 2rem;

                    .step-content {
                        margin: 0;
                    }

                    .admin__control-textarea {
                        border: 1px solid #d8d8d8;
                        border-radius: 0.2rem;
                    }
                }
            }

            .step-title {
                font-weight: 600;
                font-size: 1.9rem;
                padding: 0;
                border: 0;
            }

            .checkout-shipping-address {
                @include media-breakpoint-up(xs) {
                    padding: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    padding: 3rem;
                }
            }

            .checkout-billing-address {
                .billing-address-form {
                    max-width: unset;

                    .fieldset {
                        .field {
                            label {
                                width: auto;
                                position: relative;
                                margin: 0;
                                background-color: transparent;

                                &:after {
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }

            .checkout-shipping-address,
            .checkout-billing-address {
                .admin__control-fields {
                    padding: 0 !important;

                    legend {
                        display: none;
                    }

                    .control {
                        display: flex;
                        flex-wrap: wrap;

                        .field {
                            margin-bottom: 0;

                            &[name="shippingAddress.street.0"],
                            &[name="billingAddress.street.0"] {
                                width: 50%;
                            }

                            &[name="shippingAddress.street.1"],
                            &[name="shippingAddress.street.2"],
                            &[name="billingAddress.street.1"],
                            &[name="billingAddress.street.2"] {
                                width: 50%;
                            }

                            &._required {
                                label {
                                    &:after {
                                        content: '*';
                                        color: #e02b27;
                                        font-size: 1.2rem;
                                        margin: 0 0 0 5px;
                                    }
                                }
                            }



                            label {
                                &.label {
                                    position: relative;
                                    overflow: visible;
                                    width: auto;
                                    height: auto;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .address {
                    display: flex;
                    flex-flow: wrap;
                    margin: 0 -5px;

                    .field {
                        width: 100%;
                        padding: 0 5px;

                        &[name="shippingAddress.firstname"],
                        &[name="shippingAddress.lastname"],
                        &[name="shippingAddress.city"],
                        &[name="shippingAddress.postcode"],
                        &[name="shippingAddress.telephone"],
                        &[name="shippingAddress.country_id"] {
                            width: 50%;
                        }

                        select {
                            height: 5.2rem;
                            border: 1px solid #d8d8d8;

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        &._warn {
                            .message {
                                margin-bottom: 0;
                                padding: 1rem !important;

                                span {
                                    line-height: 1.7;

                                    &:before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #opc-shipping_method {
                @include media-breakpoint-up(xs) {
                    padding: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    padding: 3rem;
                }

                .table-checkout-shipping-method {
                    margin: 1.5rem 0;
                    width: 100%;

                    tbody {
                        tr {
                            border: 1px solid #dee0e3;
                            padding: 1rem 1.5rem;
                            cursor: pointer;

                            &:not(:first-child) {
                                margin-top: 1rem;
                            }

                            &.row {
                                margin-left: 0;
                                margin-right: 0;
                            }

                            td {
                                padding-left: 0;
                                padding-right: 0;
                                padding-bottom: 10px;
                                padding-top: 10px;
                                border: 0;

                                &.col-carrier {
                                    font-weight: 600;
                                    white-space: nowrap;
                                }

                                &.col-method {
                                    input {
                                        accent-color: $secondary;
                                    }
                                }
                            }
                        }
                    }
                }

                #shipping-method-buttons-container {
                    justify-content: flex-end;

                    .primary {
                        .button {
                            margin-top: 0;
                        }
                    }
                }
            }

            #payment {
                @include media-breakpoint-up(xs) {
                    padding: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    padding: 3rem;
                }

                .payments {
                    .fieldset {
                        br {
                            display: none;
                        }

                        hr {
                            display: none;
                        }
                    }

                    .payment-methods {
                        @include media-breakpoint-between(xs,md) {
                            margin-left: 0;
                            margin-right: 0;
                        }

                        .payment-method {
                            border: 1px solid transparent;

                            &._active {
                                background-color: rgba($secondary, 0.1);
                                border: 1px solid rgba($secondary, 0.5);
                            }

                            &:not(:first-child) {
                                margin-top: 1rem;
                            }

                            .payment-method-title {
                                margin-bottom: 0;
                                padding: 1rem;
                                border: 1px solid $light;
                                cursor: pointer;

                                &.choice {
                                    display: flex;
                                }

                                .label {
                                    margin: 0;
                                    margin-left: 1rem;

                                    img {
                                        margin-right: 1rem;
                                        float: none;
                                        margin-top: 0;
                                    }

                                    span {
                                        font-weight: 600;
                                    }
                                }
                            }

                            .payment-method-content {
                                padding: 1rem;

                                p {
                                    display: none;
                                }

                                .field-select-billing {
                                    max-width: unset;
                                    width: 100%;

                                    .paymentOptionsListTest {
                                        display: block;
                                        margin-bottom: 1rem;
                                        font-weight: 600;
                                    }

                                    .paymentOptionsList {
                                        padding-left: 0;
                                        margin-bottom: 0;
                                        display: grid;
                                        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
                                        gap: 0.5rem;

                                        li {
                                            background-color: #fff;
                                            display: flex;
                                            align-items: center;
                                            box-shadow: none !important;
                                            padding-left: 0.5rem;
                                            margin: 0;

                                            img {
                                                top: 0;
                                                height: 25px;
                                                width: auto;
                                                margin: 0 0.5rem;
                                            }

                                            label {
                                                margin-bottom: 0;
                                                display: flex;
                                                align-items: center;
                                                width: 100%;
                                                padding: 0.5rem;
                                            }
                                        }
                                    }
                                }

                                .payment-method-billing-address {
                                    background: #f9f9f9;
                                    border: 1px solid #eee;
                                    padding: 1rem;
                                    margin-top: 1rem;

                                    .checkout-billing-address {
                                        margin: 0;

                                        .billing-address-same-as-shipping-block {
                                            input {
                                                &[type="checkbox"] {
                                                    top: 0;
                                                }
                                            }
                                        }

                                        .actions-toolbar {
                                            .primary {
                                                .action-cancel {
                                                    background-color: transparent;
                                                    font-weight: 500;
                                                    color: #000;
                                                }

                                                .action-update {
                                                    color: #fff;
                                                    border-radius: 0.25rem;
                                                }
                                            }
                                        }
                                    }
                                }

                                .checkout-agreements-block {
                                    margin-top: 1rem;

                                    .checkout-agreements {
                                        .checkout-agreement {
                                            display: flex;
                                            position: relative;

                                            input {
                                                &[type="checkbox"] {
                                                    top: 0;
                                                    margin-top: 0;
                                                    height: auto;
                                                }
                                            }

                                            label  {
                                                margin-left: 0.5rem;
                                                margin-bottom: 0;
                                                margin-top: 0;
                                                position: relative;
                                                background-color: transparent;

                                                &:after {
                                                    top: 0;
                                                }

                                                button {
                                                    color: #000;
                                                    font-size: 1.2rem;
                                                }
                                            }
                                        }
                                    }
                                }

                                .actions-toolbar {
                                    .primary {
                                        margin-left: auto;

                                        button {
                                            background-color: $cta;
                                            border-color: $cta;

                                            &:hover {
                                                background-color: $cta-hover;
                                                border-color: $cta-hover;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .discount-code {
                        margin: 0;

                        .payment-option-title {
                            border-top: 0;
                            margin-top: 2rem;
                            margin-bottom: 0;
                            padding: 0;

                            #block-discount-heading {
                                color: #000;
                            }
                        }

                        .payment-option-content {
                            padding: 2rem;
                            margin-top: 1rem;
                            background-color: $light;

                            #discount-form {
                                max-width: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}
